<!--
 * @Author: 陈俊升 761723995@qq.com
 * @Date: 2023-11-11 09:19:20
 * @LastEditors: 陈俊升 761723995@qq.com
 * @LastEditTime: 2024-02-18 13:50:16
 * @FilePath: /hr_new_vue3/src/App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-config-provider :size="globalParameters.arcoSize">
    <!-- <a-spin
      :loading="globalParameters.isLoading"
      :tip="globalParameters.loadingTip"
      style="width: 100%"
      class="provider-spin"
    >
      <router-view v-if="isRouterAlive" />
    </a-spin> -->
    <router-view v-if="isRouterAlive" />
  </a-config-provider>
</template>

<script setup lang="ts">
import { ref, nextTick, provide } from 'vue';
import debounce from 'lodash.debounce';
import useGlobalParametersStore from '@/store/modules/globalParameters';
const globalParameters = useGlobalParametersStore();

//监听获取窗口大小
onMounted(() => {
  globalParameters.getWindoSize();
  window.addEventListener(
    'resize',
    debounce(() => {
      globalParameters.getWindoSize();
    }, 300)
  );
});

// 局部组件刷新
const isRouterAlive = ref(true);
const reload = () => {
  isRouterAlive.value = false;
  nextTick(() => {
    isRouterAlive.value = true;
  });
};
provide('reload', reload);
</script>

<style lang="less" scoped>
&:deep(.arco-picker) {
  width: 100%;
}
.provider-spin {
  width: 100%;
  height: 100%;
}
</style>
<style lang="less">
html,
body,
#app {
  // min-width: 600px;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* 树形选择器缩进为0 */
/* 用来解决arco树形组件在scoped下修改缩进样式失效的问题 */
.tree-drop-down-indent-size {
  .arco-tree-node-indent {
    width: 0;
    height: 0;
  }
}

// modal和drawer下面的按钮居中
.arco-modal-footer,
.arco-drawer-footer {
  text-align: center;
}
</style>
