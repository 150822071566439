import { provide, inject } from 'vue';

const cjs = {
  set() {
    console.log('set cjs');
  },
  show() {
    console.log('show cjs');
  },
  install(app: any) {
    const cjss: any = this;
    app.config.globalProperties.$cjs = cjss;
    app.provide('cjs', cjss);
  }
};

const useCjs = function () {
  return inject('cjs');
};

export { cjs, useCjs };
