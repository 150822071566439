/*
 * @Author: 陈俊升 761723995@qq.com
 * @Date: 2023-11-11 09:19:20
 * @LastEditors: 陈俊升 761723995@qq.com
 * @LastEditTime: 2024-02-21 11:18:14
 * @FilePath: /hr_new_vue3/src/main.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue';
// arco——design
import './style.css';
import ArcoVue from '@arco-design/web-vue';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
// import ArcoVueCalendar from '@arco-design/web-vue/es';
import '@arco-design/web-vue/dist/arco.css';
import App from './App.vue';
import { usePermissionStore } from '@/store';
import announcementService from '@/utils/announcementService';

//routes

//pinia
import { createPinia } from 'pinia';
import store from './store';
import { Message } from '@arco-design/web-vue';

import router from '@/router';

import { cjs } from '@/plugin/cjs';

const app = createApp(App);

// 初始化公告服务
// announcementService.initWebSocket();

// 在全局提供公告服务
// app.config.globalProperties.$announcementService = announcementService;

// 未做功能信息提示指令
app.directive('dev', {
  mounted(el) {
    el.onclick = () => {
      Message.warning('敬请期待~');
    };
  }
});
app.directive('focus', {
  mounted(el) {
    const inputDom = el.querySelectorAll('input')[0];
    inputDom.focus();
  }
});

// arco——design
app.use(ArcoVue, {});
app.use(ArcoVueIcon);
app.use(cjs);
// app.use(ArcoVueCalendar);

//router
app.use(router);
//store
app.use(store);

app.mount('#app');
app.config.warnHandler = () => null;

//权限管理指令 参数 hooksId列表 string[]
const accountStore = usePermissionStore();
app.directive('role', {
  mounted(el, { value }) {
    console.log(22, el, value);
    const hookIdList = accountStore.apiList;
    console.log('hookIdList', hookIdList);
    for (const iterator in value) {
      console.log(value[iterator]);
      if (hookIdList.includes(value[iterator])) return;
    }
    el.remove();
  }
});
